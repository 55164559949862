<template>
  <div>
    <NavBar class="navbar">
      <div slot="title">签到优惠券活动规则</div>
    </NavBar>
    <div class="main">
      <div v-html="rule"></div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/layout/nav-bar/nav-bar";
import { getSignRule } from "@/api/sign.js";
export default {
  name: "rule",
  components: {
    NavBar,
  },
  data() {
    return {
      rule: "",
    };
  },
  mounted() {
    // this.getSignRule();
  },
  methods: {
    async getSignRule() {
      const res = await getSignRule();
      if (res.code === 1) {
        this.rule = res.data;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.navbar {
  background-color: #fff;
}
.main {
  min-height: 100vh;
  padding: 52px 12px 0 12px;
  background-color: #fff;
  box-sizing: border-box;
}
</style>